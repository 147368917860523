.contact-rich {
  h4 {
  }
  hr {
    border-style: dashed;
  }
  .block {
    display: flex;
    .icon {
      width: 3.5rem;
      i {
        font-size: 2rem;
      }
    }
    .data {
    }
  }
}
.contact-form {
}
