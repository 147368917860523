$category-block-padding: 20px;
.aone-featuredcategories {
  margin-bottom: $category-block-padding;
  .title-block {
    .title-icon {
      margin-top: -3px;
      margin-right: 8px;
    }
  }
  .category-block {
    padding: 0 ($category-block-padding / 2);
    margin-bottom: 19px;
    margin-top: 1px;
    .category-container {
      overflow: hidden;
      height: 100%;
      background: $box-background;
      box-shadow: 0px 1px 5px $box-shadow-color;
      border-radius: 5px;
    }
    .category-image {
      text-align: center;
      img {
        margin: 0 auto;
        &:hover {
          filter: brightness(75%);
        }
      }
    }
    .category-name {
      font-size: 1.125rem;
      text-align: center;
      text-transform: uppercase;
      margin: 0;
      padding: 10px 5px;
      background: $color_gray;
      @include media-breakpoint-down(xs) {
        font-size: 1rem;
      }
    }
    .category-des {
      text-align: center;
      padding: 10px;
      display: none;
    }
    .sub-categories {
      text-align: left;
      overflow: hidden;
      padding: 10px 10px;
      @include media-breakpoint-up(xs) {
        padding-left: 5px;
        padding-right: 5px;
      }
      li {
        padding: 7px 5px;
        width: 100%;
        @include media-breakpoint-down(xs) {
          width: 50%;
        }
      }
    }
    .shop-now {
      text-align: center;
      margin-top: 25px;
      display: none;
    }
  }
  .category-list {
    margin: 0 ($category-block-padding / -2);
    display: flex;
    flex-wrap: wrap;
    .category-block {
      @include make-col(1, 1);
    }
    &.per-row-2 {
      .category-block {
        @include media-breakpoint-up(lg) {
          @include make-col(1, 2);
          .sub-categories li {
            width: 50%;
          }
        }
        @include media-breakpoint-up(sm) {
          .sub-categories li {
            width: 50%;
          }
        }
      }
    }
    &.per-row-3 {
      .category-block {
        @include media-breakpoint-up(sm) {
          @include make-col(1, 2);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(1, 3);
        }
        @include media-breakpoint-up(xl) {
          .sub-categories li {
            width: 50%;
          }
        }
      }
    }
    &.per-row-4 {
      .category-block {
        @include media-breakpoint-up(sm) {
          @include make-col(1, 2);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(1, 4);
        }
      }
    }
  }

  .category-list-flex {
  }

  .category-list-slider {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    &.slick-slider {
      display: block;
      overflow: visible;
      .category-block {
        flex: 0 1 auto !important;
        max-width: none !important;
      }
    }
  }

  .categories-mobile-slider {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
    overflow-x: scroll;
    overflow-y: hidden;
    touch-action: auto;
    margin-right: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .category-block {
      flex: 0 1 auto !important;
      max-width: none !important;
      min-width: 85%;
      .sub-categories {
        li {
          width: 100%;
          @include media-breakpoint-only(sm) {
            width: 50%;
          }
        }
      }
    }
  }
}