#pagenotfound {
  #main {
    max-width: 600px;
    margin: 0 auto;
  }
  .page-not-found {
    .search-widget {
    }
  }
}
