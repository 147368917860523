.customization-modal {
  .modal-content {
    .modal-body {
      padding-top: 0;
      .product-customization-line {
        padding-top: 15px;
        + .product-customization-line {
          margin-top: 15px;
          border-top: 1px solid $border-color-lighten;
        }
      }
    }
  }
}