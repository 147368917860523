.block-reassurance {
  img {
    color: $icon-color;
    width: 25px;
    margin-right: 10px;
  }
  li + li {
    border-top: 1px dashed $border-color;
  }
  li .block-reassurance-item {
    padding: $box-padding;
  }
}
